
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { ErrorLayout } from 'common/layouts/ErrorLayout'
/** @jsxImportSource theme-ui */
import { NextPage } from 'next'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'

const PageNotFound: NextPage = () => {
  const { t } = useTranslation('errors')
  return (
    <>
      <Head>
        <title>{t('page-titles:404')}</title>
      </Head>
      <ErrorLayout
        title={t('error-page.not-found-title')}
        message={t('error-page.not-found-sub')}
        code="404"
      />
    </>
  )
}

export default PageNotFound


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  